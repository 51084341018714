import React, { useEffect, useState } from 'react'
import { 
    getPolizaData,
    getCheckoutData
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './PolizasData.css'
import Moment from 'moment/moment'
import loadingLottie from '../../../assets/lotties/loading.json'
import Lottie from 'react-lottie';
import { estadoList } from '../../../util/EstadoListColors'

const PolizasData = (props: any) => {

    const [polizaData, setPolizaData] = useState<any>(null)
    const [checkoutData, setCheckoutData] = useState<any>(null)
    const [packageData, setPackageData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        getPolizaData(props.polizaId, setPolizaData, props.from, setLoading)
    }, [])

    useEffect(() => {
        if(polizaData) getCheckoutData(polizaData.checkoutId, setCheckoutData, setLoading)
    }, [polizaData])

    useEffect(() => {
        if(checkoutData) setPackageData(checkoutData?.offer?.packages?.filter((a: any) => a?.id === checkoutData?.offer?.currentPackage)[0])
    }, [checkoutData])

    return (
        <div className='polizasData-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='polizasData-container-data'>
                    <div className='polizasData-container-data-section'>
                        <CustomTextInput
                            label='NRO DE COBERTURA'
                            value={props.polizaId}
                            disabled
                            color={estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color}
                        />
                        <CustomTextInput
                            label='ESTADO'
                            value={estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.title}
                            disabled
                            color={estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color}
                        />
                        <CustomTextInput
                            label='FECHA DE ALTA'
                            value={Moment(polizaData?.fechaDesde).format('DD/MM/YYYY')}
                            disabled
                        />
                        <CustomTextInput
                            label='CATEGORÍA'
                            value={polizaData?.productPolicyDTO?.categoryName}
                            disabled
                        />
                        <CustomTextInput
                            label='ESPECIFICACIONES'
                            value={packageData?.name}
                            disabled
                        />
                        <CustomTextInput
                            label='ASEGURA'
                            value={polizaData?.productPolicyDTO?.insurerName}
                            disabled
                        />
                        <CustomTextInput
                            label='PAGA'
                            value={polizaData?.coveragePolicyDTO?.valueToPay}
                            disabled
                            underText={'PRÓX CUOTA: ' + polizaData?.coveragePolicyDTO?.valueToPay}
                            underColor='var(--warningPrimary)'
                        />
                        <CustomTextInput
                            label='POR'
                            value='MES'
                            disabled
                        />
                        <CustomTextInput
                            label='VIGENCIA'
                            value='ANUAL'
                            disabled
                        />
                    </div>
                    <h5 className='polizasData-container-data-title bold'>Más datos</h5>
                    <div className='polizasData-container-data-section'>
                        {packageData?.coverageGroups[0]?.coverages?.map((a: any, index: any) => (
                            <CustomTextInput
                                key={index}
                                label={a.title?.toUpperCase()}
                                value={a.value}
                                disabled
                            />
                        ))}
                    </div>
                </div>
            }
        </div>
    );

}

export default PolizasData;