import React, { useEffect, useRef, useState } from 'react'
import './IndividualActions.css'

const IndividualActions = (props: any) => {  

    const [open, setOpen] = useState<boolean>(false);
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (listRef.current && !listRef?.current?.contains(event.target)) {
                setOpen(false) 
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [listRef]);

    return (
        <div className='individualActions-container'>
            <div className='individualActions-container-icon' onClick={() => props.disabled ? {} : setOpen(!open)}>
                <span className="material-symbols-rounded" style={{ color: 'var(--primary500)', fontSize: 24 }}>more_vert</span>
            </div>
            {open &&
                <div ref={listRef} className='individualActions-container-list'>
                    {props.list?.map((a: any, index: any) => (
                        <div 
                            ref={listRef} 
                            className='individualActions-container-list-item'
                            style={{ display: a.show ? 'flex' : 'none' }}
                            onClick={() => a.action()}
                            key={index}
                        >
                            <b className='individualActions-container-list-item-text regular medium'>{a.label}</b>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}

export default IndividualActions;