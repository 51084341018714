import axios from './instanceActions'
import { 
    netUserClientData, 
    getCivilTypes,
    getSexTypes,
    getStatesCon,
    getLocByIdCon,
    netUserPolizasData,
    netUserCotizacionesData,
    netUserClaimsData,
    netUserDataFromClaim,
    netUserDataFromPoliza,
    getPardoData,
    getPardoLocalidades,
    getPardoCodigosPostales,
    editUserById
} from "../net/Connector";

export const getUserClaimsData = (userId: any, setUserClaimsData: any, setLoading: any) => {
    setLoading(true)
    axios.get(netUserClaimsData(userId))
        .then((response) => {
            setUserClaimsData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getUserCotizacionesData = (userId: any, setUserCotizaiconesData: any, setLoading: any) => {
    setLoading(true)
    axios.get(netUserCotizacionesData(userId))
        .then((response) => {
            setUserCotizaiconesData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getUserPolizasData = (userId: any, setUserPolizasData: any, setLoading: any) => {
    setLoading(true)
    axios.get(netUserPolizasData(userId))
        .then((response) => {
            setUserPolizasData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getUserClientDataWeco = (userId: any, setUserClientData: any, setGeneroList: any, setCivilList: any, setLocalidad: any, setProvinciaList: any, from: any, setLoading: any) => {
    setLoading(true)
    let url = '';
    if(from === 'claim') {
        url = netUserDataFromClaim(userId);
    } else if(from === 'poliza') {
        url = netUserDataFromPoliza(userId);
    } else {
        url = netUserClientData(userId);
    }
    axios.get(url)
        .then((response) => {
            let userData = response.data
            setUserClientData(userData)
            axios.get(getCivilTypes())
                .then(responseCivil => {
                    let objCivilType: any = [];
                    responseCivil.data.map((a: any) => {
                        let item: any = {codigo: a.codigo, label: a.descripcion}
                        objCivilType.push(item)
                    })
                    setCivilList(objCivilType)
                    axios.get(getSexTypes())
                        .then(responseS => {
                            let arraySexTypes: any = [];
                            responseS.data.map((a: any) => {
                                let item: any = { codigo: a.codigo, label: a.descripcion}
                                arraySexTypes.push(item)
                            })
                            setGeneroList(arraySexTypes)
                            axios.get(getStatesCon()).then(responseStates => {
                                setProvinciaList(responseStates.data)
                                axios.get(getLocByIdCon(userData.localidadId)).then(responseLoc => {
                                    setLocalidad(responseLoc.data[0]?.localidad)
                                    setLoading(false)
                                }).catch(error => {
                                    setLoading(false)
                                });
                            }).catch(error => {
                                setLoading(false)
                            });
                        })
                        .catch(error => {
                            setLoading(false)
                        })
                })
                .catch(error => {
                    setLoading(false)
                });
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getUserClientDataPardo = (userId: any, setUserClientData: any, from: any, setLoading: any) => {
    setLoading(true)
    let url = '';
    if(from === 'claim') {
        url = netUserDataFromClaim(userId);
    } else if(from === 'poliza') {
        url = netUserDataFromPoliza(userId);
    } else {
        url = netUserClientData(userId);
    }
    axios.get(url)
        .then((response) => {
            let userData = response.data
            setUserClientData(userData)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const editUserProfileArgPardo = (
    nombre: any, apellido: any, dateGoodFormat: any,
    tipoDocu: any, numDocu: any, cuit: any,
    sexo: any, estadoCivil: any, nacionalidad: any,
    celular: any, email: any,
    tipoDomi: any, calle: any, nro: any, piso: any, dpto: any,
    localidad: any, localidadText: any, codigoPostal: any, provincia: any, provinciaText: any,
    userId: any, setLoading: any, setUserClientData: any, setNotification: any, from: any
) => {
    const fastUserData = {
        'name': nombre,
        'lastName': apellido,
        'birthDate': dateGoodFormat,

        'documentId': tipoDocu,
        'documentNumber': numDocu,
        'cuil': cuit,

        'sexId': sexo,
        'civilStatus': estadoCivil,
        'nacionalityId': nacionalidad,
        
        'phone': celular,
        'userName': email,

        'addressType': tipoDomi,
        'addressStreet': calle + ' ' + nro,
        'addressFloor': piso,
        'dpto': dpto,
        
        'provincia': parseInt(provincia, 10),
        'provinciaText': provinciaText,
        'localidad': parseInt(localidad, 10),
        'localidadText': localidadText,
        'addressPostalCode': codigoPostal,
    }
    debugger
    setLoading(true)
    axios.patch(editUserById(userId), fastUserData)
        .then(response => {
            let url = '';
            if(from === 'claim') {
                url = netUserDataFromClaim(userId);
            } else if(from === 'poliza') {
                url = netUserDataFromPoliza(userId);
            } else {
                url = netUserClientData(userId);
            }
            axios.get(url)
                .then((response) => {
                    setUserClientData(response.data)
                    setLoading(false)
                    setNotification({success: true, text: 'Los cambios se guardaron con éxito.'})
                    setTimeout(() => {
                        setNotification({success: false, text: ''})
                    }, 5000)
                })
                .catch((error) => {
                    setLoading(false)
                    setNotification({success: false, text: 'No pudimos guardar los cambios. Por favor, intentá nuevamente. (' + error?.response?.data?.message + ')'})
                    setTimeout(() => {
                        setNotification({success: false, text: ''})
                    }, 5000)
                })
        })
        .catch(error => {
            setLoading(false)
            setNotification({success: false, text: 'No pudimos guardar los cambios. Por favor, intentá nuevamente. (' + error?.response?.data?.message + ')'})
            setTimeout(() => {
                setNotification({success: false, text: ''})
            }, 5000)
        });
}

export const actionEditSuperSeller = (data: any, userId: any, setLoading: any, setSuccess: any, setError: any) => {
    const fastUserData = data;
    setLoading(true)
    axios.patch(editUserById(userId), fastUserData)
        .then(response => {
            setLoading(false)
            setSuccess(true)
        })
        .catch(error => {
            setLoading(false)
            setError(error?.response?.data?.errors[0])
        });
}

//LISTADOS

export const getListsArgPardo = (setTipoDocuList: any, setSexoList: any, setEstadoCivilList: any, setNacionalidadList: any, setTipoDomiList: any, setProvinciaList: any, setLocalidadList: any, setCodigoPostalList: any, userClientData: any) => {
    axios.get(getPardoData("tipo_documentos")).then(responseTipoDocumentos => {
        setTipoDocuList(responseTipoDocumentos.data);
        axios.get(getPardoData("sexos")).then(responseSexos => {
            setSexoList(responseSexos.data);
            axios.get(getPardoData("estado_civil")).then(responseEstadoCivil => {
                setEstadoCivilList(responseEstadoCivil.data);
                axios.get(getPardoData("paises")).then(responsePaises => {
                    setNacionalidadList(responsePaises.data);
                    axios.get(getPardoData("tipo_domicilio")).then(responseTipoDomicilio => {
                        setTipoDomiList(responseTipoDomicilio.data);
                        axios.get(getPardoData("provincias")).then(responseProvincias => {
                            setProvinciaList(responseProvincias.data);
                            axios.get(getPardoLocalidades(userClientData.provinciaId)).then(responseLocalidades => {
                                setLocalidadList(responseLocalidades.data);
                                axios.get(getPardoCodigosPostales(userClientData.localidadId)).then(responseCodigosPostales => {
                                    setCodigoPostalList(responseCodigosPostales.data);
                                }).catch(error => {
                                });
                            }).catch(error => {
                            });
                        }).catch(error => {
                        });
                    }).catch(error => {
                    });
                }).catch(error => {
                });
            }).catch(error => {
            });
        }).catch(error => {
        });
    }).catch(error => {
    });
}

export const getLocalidadListPardo = (provincia: any, setLocalidadList: any) => {
    axios.get(getPardoLocalidades(provincia)).then(responseLocalidades => {
        setLocalidadList(responseLocalidades.data);
    }).catch(error => {
    });
}

export const getCodigoPostalListPardo = (localidad: any, setCodigoPostalList: any) => {
    axios.get(getPardoCodigosPostales(localidad)).then(responseCodigosPostales => {
        setCodigoPostalList(responseCodigosPostales.data);
    }).catch(error => {
    });
}