import React, { useState } from 'react'
import './CustomTextInput.css'
import InputError from '../../assets/images/svgs/inputError.svg';
import Lupa from '../../assets/images/svgs/lupa.svg';
import EyeClose from '../../assets/images/svgs/eyeClose.svg';
import EyeOpen from '../../assets/images/svgs/eyeOpen.svg';
import { ReactSVG } from 'react-svg'
import Modal from 'react-modal';
import GoogleMapModal from '../Modals/GoogleMapModal/GoogleMapModal';
import ImageModal from '../Modals/ImageModal/ImageModal';

const CustomTextInput = (props: any) => {

    const [showPassword, setShowPassword] = useState(false)
    const [inputFocus, setInputFocus] = useState(false)
    const [dymModal, setDymModal] = useState(false)
    const [copyTextAlert, setCopyTextAlert] = useState(false)

    const iconSize = (svg: any) => {
        svg.setAttribute('style', 'height: ' + 23);
        svg.setAttribute('style', 'width: ' + 23);
    }

    const DymModal = () => {
        return (
            <Modal
                isOpen={dymModal}
                onRequestClose={() => setDymModal(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '30%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <div className='customTextInput-modalContainer'>
                    <div className='customTextInput-modalContainer-close' onClick={() => setDymModal(false)}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.00011 8.40005L2.10011 13.3C1.91678 13.4834 1.68344 13.575 1.40011 13.575C1.11678 13.575 0.883443 13.4834 0.700109 13.3C0.516776 13.1167 0.42511 12.8834 0.42511 12.6C0.42511 12.3167 0.516776 12.0834 0.700109 11.9L5.60011 7.00005L0.700109 2.10005C0.516776 1.91672 0.42511 1.68338 0.42511 1.40005C0.42511 1.11672 0.516776 0.883382 0.700109 0.700048C0.883443 0.516715 1.11678 0.425049 1.40011 0.425049C1.68344 0.425049 1.91678 0.516715 2.10011 0.700048L7.00011 5.60005L11.9001 0.700048C12.0834 0.516715 12.3168 0.425049 12.6001 0.425049C12.8834 0.425049 13.1168 0.516715 13.3001 0.700048C13.4834 0.883382 13.5751 1.11672 13.5751 1.40005C13.5751 1.68338 13.4834 1.91672 13.3001 2.10005L8.40011 7.00005L13.3001 11.9C13.4834 12.0834 13.5751 12.3167 13.5751 12.6C13.5751 12.8834 13.4834 13.1167 13.3001 13.3C13.1168 13.4834 12.8834 13.575 12.6001 13.575C12.3168 13.575 12.0834 13.4834 11.9001 13.3L7.00011 8.40005Z" fill="#333333"/>
                        </svg>
                    </div>
                    {props.img ?
                        <ImageModal img={props.img}/>
                        :
                        <GoogleMapModal
                            lat={props.lat}
                            lng={props.lng}
                        />
                    }
                </div>
            </Modal>
        )
    }

    const getIcons = () => {
        if (props.img) {
            return (
                <div 
                    onClick={() => setDymModal(true)}
                    style={{cursor: 'pointer'}}
                >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.9 17.3L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.146 12.371 1.888 11.113C0.629333 9.85433 0 8.31667 0 6.5C0 4.68333 0.629333 3.14567 1.888 1.887C3.146 0.629 4.68333 0 6.5 0C8.31667 0 9.85433 0.629 11.113 1.887C12.371 3.14567 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L17.325 15.925C17.5083 16.1083 17.596 16.3373 17.588 16.612C17.5793 16.8873 17.4833 17.1167 17.3 17.3C17.1167 17.4833 16.8833 17.575 16.6 17.575C16.3167 17.575 16.0833 17.4833 15.9 17.3ZM6.5 11C7.75 11 8.81267 10.5627 9.688 9.688C10.5627 8.81267 11 7.75 11 6.5C11 5.25 10.5627 4.18733 9.688 3.312C8.81267 2.43733 7.75 2 6.5 2C5.25 2 4.18733 2.43733 3.312 3.312C2.43733 4.18733 2 5.25 2 6.5C2 7.75 2.43733 8.81267 3.312 9.688C4.18733 10.5627 5.25 11 6.5 11ZM6.5 9.5C6.21667 9.5 5.97933 9.404 5.788 9.212C5.596 9.02067 5.5 8.78333 5.5 8.5V7.5H4.475C4.19167 7.5 3.95833 7.404 3.775 7.212C3.59167 7.02067 3.5 6.78333 3.5 6.5C3.5 6.21667 3.59567 5.979 3.787 5.787C3.979 5.59567 4.21667 5.5 4.5 5.5H5.5V4.475C5.5 4.19167 5.596 3.95833 5.788 3.775C5.97933 3.59167 6.21667 3.5 6.5 3.5C6.78333 3.5 7.021 3.59567 7.213 3.787C7.40433 3.979 7.5 4.21667 7.5 4.5V5.5H8.525C8.80833 5.5 9.04167 5.59567 9.225 5.787C9.40833 5.979 9.5 6.21667 9.5 6.5C9.5 6.78333 9.40433 7.02067 9.213 7.212C9.021 7.404 8.78333 7.5 8.5 7.5H7.5V8.525C7.5 8.80833 7.40433 9.04167 7.213 9.225C7.021 9.40833 6.78333 9.5 6.5 9.5Z" fill="#7963E0"/>
                    </svg>
                </div>
            )
        } else if (props.maps) {
            return (
                <div 
                    onClick={() => setDymModal(true)}
                    style={{cursor: 'pointer'}}
                >
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 10C8.55 10 9.021 9.804 9.413 9.412C9.80433 9.02067 10 8.55 10 8C10 7.45 9.80433 6.979 9.413 6.587C9.021 6.19567 8.55 6 8 6C7.45 6 6.97933 6.19567 6.588 6.587C6.196 6.979 6 7.45 6 8C6 8.55 6.196 9.02067 6.588 9.412C6.97933 9.804 7.45 10 8 10ZM8 17.35C10.0333 15.4833 11.5417 13.7873 12.525 12.262C13.5083 10.7373 14 9.38333 14 8.2C14 6.38333 13.4207 4.89567 12.262 3.737C11.104 2.579 9.68333 2 8 2C6.31667 2 4.89567 2.579 3.737 3.737C2.579 4.89567 2 6.38333 2 8.2C2 9.38333 2.49167 10.7373 3.475 12.262C4.45833 13.7873 5.96667 15.4833 8 17.35ZM8 19.625C7.86667 19.625 7.73333 19.6 7.6 19.55C7.46667 19.5 7.35 19.4333 7.25 19.35C4.81667 17.2 3 15.2043 1.8 13.363C0.6 11.521 0 9.8 0 8.2C0 5.7 0.804333 3.70833 2.413 2.225C4.021 0.741667 5.88333 0 8 0C10.1167 0 11.979 0.741667 13.587 2.225C15.1957 3.70833 16 5.7 16 8.2C16 9.8 15.4 11.521 14.2 13.363C13 15.2043 11.1833 17.2 8.75 19.35C8.65 19.4333 8.53333 19.5 8.4 19.55C8.26667 19.6 8.13333 19.625 8 19.625Z" fill="#7963E0"/>
                    </svg>
                </div>
            )
        } else if (props.copy) {
            return (
                <div 
                    onClick={() => {
                        navigator.clipboard.writeText(props.value)
                        setCopyTextAlert(true)
                        setTimeout(() => {
                            setCopyTextAlert(false)
                        }, 1000)
                    }}
                    style={{cursor: 'pointer'}}
                >
                    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14ZM2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V5C0 4.71667 0.0960001 4.479 0.288 4.287C0.479333 4.09567 0.716667 4 1 4C1.28333 4 1.521 4.09567 1.713 4.287C1.90433 4.479 2 4.71667 2 5V18H12C12.2833 18 12.521 18.096 12.713 18.288C12.9043 18.4793 13 18.7167 13 19C13 19.2833 12.9043 19.5207 12.713 19.712C12.521 19.904 12.2833 20 12 20H2Z" fill="#7963E0"/>
                    </svg>
                </div>
            )
        } else if(props.lupa) {
            if(props.loading) {
                return (
                    <div className='loading-customTextInput'>
                        <span className='loading-customTextInput-loader'></span>
                    </div>
                )
            }
            if(props.value !== '') {
                return (
                    <span onClick={() => props.lupa()} className="material-symbols-rounded" style={{ color: 'var(--primary500)', cursor: 'pointer' }}>close</span>
                )
            } else {
                return (
                    <span className="material-symbols-rounded" style={{ color: 'var(--primary500)'}}>search</span>
                )
            }
        } else if (props.success && !props.ispassword) {
            return (
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.55061 11.5749C5.41728 11.5749 5.29228 11.5539 5.17561 11.5119C5.05894 11.4706 4.95061 11.3999 4.85061 11.2999L0.550611 6.9999C0.367278 6.81657 0.279611 6.5789 0.287611 6.2869C0.296278 5.99557 0.392277 5.75824 0.575611 5.5749C0.758944 5.39157 0.992278 5.2999 1.27561 5.2999C1.55894 5.2999 1.79228 5.39157 1.97561 5.5749L5.55061 9.1499L14.0256 0.674902C14.2089 0.491569 14.4466 0.399902 14.7386 0.399902C15.0299 0.399902 15.2673 0.491569 15.4506 0.674902C15.6339 0.858236 15.7256 1.09557 15.7256 1.3869C15.7256 1.6789 15.6339 1.91657 15.4506 2.0999L6.25061 11.2999C6.15061 11.3999 6.04228 11.4706 5.92561 11.5119C5.80894 11.5539 5.68394 11.5749 5.55061 11.5749Z" fill="#008744"/>
                </svg>
            )
        } else if(props.error && !props.ispassword) {
            return (
                <ReactSVG
                    src={InputError}
                    beforeInjection={svg => iconSize(svg)}
                />
            )
        } else {
            if(props.ispassword && props.value.length > 0) {
                if(showPassword) {
                    return (
                        <ReactSVG
                            onClick={() => setShowPassword(!showPassword)}
                            src={EyeOpen}
                            beforeInjection={svg => iconSize(svg)}
                        />
                    )
                } else {
                    return (
                        <ReactSVG
                            onClick={() => setShowPassword(!showPassword)}
                            src={EyeClose}
                            beforeInjection={svg => iconSize(svg)}
                        />
                    )
                }
            }
        }
    }

    return (
        <div className='customTextInput-container'>
            <div className='customTextInput-container-label'>
                <p className='customTextInput-container-label-text bold medium'>{props.label}</p>
                {props.required && <p className='bold medium' style={{ color: 'var(--errorPrimary)' }}>&nbsp;*</p>}
                {props.tooltiptext && <div className="customTextInput-container-label-tooltip">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 11.75C8.2125 11.75 8.39063 11.6781 8.53438 11.5344C8.67813 11.3906 8.75 11.2125 8.75 11V7.98125C8.75 7.76875 8.67813 7.59375 8.53438 7.45625C8.39063 7.31875 8.2125 7.25 8 7.25C7.7875 7.25 7.60938 7.32188 7.46563 7.46563C7.32188 7.60938 7.25 7.7875 7.25 8V11.0188C7.25 11.2313 7.32188 11.4063 7.46563 11.5438C7.60938 11.6813 7.7875 11.75 8 11.75ZM8 5.75C8.2125 5.75 8.39063 5.67813 8.53438 5.53438C8.67813 5.39063 8.75 5.2125 8.75 5C8.75 4.7875 8.67813 4.60937 8.53438 4.46562C8.39063 4.32187 8.2125 4.25 8 4.25C7.7875 4.25 7.60938 4.32187 7.46563 4.46562C7.32188 4.60937 7.25 4.7875 7.25 5C7.25 5.2125 7.32188 5.39063 7.46563 5.53438C7.60938 5.67813 7.7875 5.75 8 5.75ZM8 15.5C6.9625 15.5 5.9875 15.3031 5.075 14.9094C4.1625 14.5156 3.36875 13.9813 2.69375 13.3063C2.01875 12.6313 1.48438 11.8375 1.09063 10.925C0.696875 10.0125 0.5 9.0375 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.01875 3.36875 2.69375 2.69375C3.36875 2.01875 4.1625 1.48438 5.075 1.09063C5.9875 0.696875 6.9625 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.0375 15.3031 10.0125 14.9094 10.925C14.5156 11.8375 13.9813 12.6313 13.3063 13.3063C12.6313 13.9813 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14C9.6625 14 11.0781 13.4156 12.2469 12.2469C13.4156 11.0781 14 9.6625 14 8C14 6.3375 13.4156 4.92188 12.2469 3.75313C11.0781 2.58438 9.6625 2 8 2C6.3375 2 4.92188 2.58438 3.75313 3.75313C2.58438 4.92188 2 6.3375 2 8C2 9.6625 2.58438 11.0781 3.75313 12.2469C4.92188 13.4156 6.3375 14 8 14Z" fill="#666666"/>
                    </svg>
                    <span className="customTextInput-container-label-tooltiptext">{props.tooltiptext}</span>
                </div>}
            </div>
            <div 
                className={inputFocus ? 'customTextInput-container-inputContainerFocus' : 'customTextInput-container-inputContainer'}
                style={{ backgroundColor: props.disabled ? 'transparent' : 'var(--white)' }}
            >
                <input 
                    id={props.label ? props.label + ' input' : props.id}
                    className='customTextInput-container-inputContainer-input regular medium' 
                    onFocus={() => setInputFocus(true)}
                    onBlur={() => setInputFocus(false)}
                    type={props.ispassword ? !showPassword ? 'password' : 'default' : 'default'}
                    style={{ 
                        backgroundColor: props.disabled ? 'transparent' : 'var(--white)',
                        color: props.color ? props.color : 'var(--gray700)'
                    }}
                    {...props}
                />
                {getIcons()}
            </div>
            {props.error && <p className='customTextInput-container-error regular medium'>{props.errorMessage}</p>}
            {props.underText && <p className='customTextInput-container-underText regular small' style={{ color: props.underColor}}>{props.underText}</p>}
            <p 
                className='customTextInput-container-textCopy regular small'
                style={{ opacity: copyTextAlert ? 1 : 0}}
            >{props.copy}</p>
            {DymModal()}
        </div>
    );

}

export default CustomTextInput;