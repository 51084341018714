import React from 'react'
import './ItemPregunta.css'

const ItemPregunta = (props: any) => {

    return (
        <div 
            className='itemPregunta-container'
            style={{ borderBottom: props.isLast ? 'none' : '1px solid var(--gray300)'}}
        >
            <p className='itemPregunta-container-text regular'>{props.numero + '. ' + props.pregunta}</p>
            <div className='itemPregunta-container-valor'>
                <div className='itemPregunta-container-valor'>
                    <p className='itemPregunta-container-valor-text'>SI</p>
                    <div className='itemPregunta-container-valor-circle'>
                        {props.selected && <div className='itemPregunta-container-valor-circle-inner'/>}
                    </div>
                </div>
                <div className='itemPregunta-container-valor'>
                    <p className='itemPregunta-container-valor-text'>NO</p>
                    <div className='itemPregunta-container-valor-circle'>
                        {!props.selected && <div className='itemPregunta-container-valor-circle-inner'/>}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ItemPregunta;