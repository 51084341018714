import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Login from './pages/Login/Login';
import Responsive from './pages/Responsive/Responsive';
import PasswordRecovery from './pages/Recovery/Password/PasswordRecovery/PasswordRecovery';
import PasswordRecoveryCode from './pages/Recovery/Password/PasswordRecoveryCode/PasswordRecoveryCode';
import PasswordRecoveryForm from './pages/Recovery/Password/PasswordRecoveryForm/PasswordRecoveryForm';
import PasswordRecoveryFinish from './pages/Recovery/Password/PasswordRecoveryFinish/PasswordRecoveryFinish';
import Cookies from 'universal-cookie';
import ColorRoute from './Routers/ColorRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import AuthRoute from './Routers/AuthRoute';
import { actionUserMine } from './actions';
import Polizas from './pages/Polizas/Polizas';
import CrearPoliza from './pages/CrearPoliza/CrearPoliza';
import Users from './pages/Users/Users';
import CrearUser from './pages/CrearUser/CrearUser';
import ProtectedRoute from './Routers/ProtectedRoute';
import Claims from './pages/Claims/Claims';
import CrearClaim from './pages/CrearClaim/CrearClaim';
import ProductSuggest from './pages/ProductSuggest/ProductSuggest';
import CrearProductSuggest from './pages/CrearProductSuggest/CrearProductSuggest';
import Discounts from './pages/Discounts/Discounts';
import CrearDiscounts from './pages/CrearDiscounts/CrearDiscounts';
import Activities from './pages/Activities/Activities';
import Checkouts from './pages/Checkouts/Checkouts';
import ContactForm from './pages/ContactForm/ContactForm';
import MotorbikesPrices from './pages/MotorbikesPrices/MotorbikesPrices';
import CarPrices from './pages/CarPrices/CarPrices';
import { IntlProvider } from 'react-intl';
import { countries } from './data';
import UserDetails from './pages/UserDetails/UserDetails';
import PolizasDetails from './pages/PolizasDetails/PolizasDetails';
import ClaimsDetails from './pages/ClaimsDetails/ClaimsDetails';
import PendingPolizas from './pages/PendingPolizas/PendingPolizas';
import Sellers from './pages/Sellers/Sellers';
import SuperSellers from './pages/SuperSellers/SuperSellers';

const App = (props: any) => {

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [userAccessToken, setUserAccessToken] = useState<any>(null);
  const [userData, setUserData] = useState<any>(null);
  const [userList, setUserList] = useState<any>(null);
  const [principalList, setPrincipalList] = useState<any>(null);
  const [lang, setLang] = useState<any>(undefined);

  const loadLanguage = (country: number) => {
    countries.find(c => c.id === country)?.lang().then((messages) => {
      setLang(messages);
    });
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    loadLanguage(countries[0].id)
    const cookies = new Cookies();
    cookies.set('channelId', process.env.REACT_APP_ID);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    if(userAccessToken) {
      actionUserMine(setUserData, setUserList, setPrincipalList)
    }
  }, [userAccessToken])

  const textStyle = { fontFamily: 'Lato, sans-serif' };

  return (
    <div style={textStyle}>
      <IntlProvider locale={"es"} messages={lang} onError={(e) => {}}>
        <Router>
          <Routes>
            {width <= 600 ?
              <React.Fragment>
                <Route path="/" element={<Responsive/>} />
                <Route path="*" element={<Navigate to={"/"} />} />
              </React.Fragment>
              :
              <React.Fragment>
                <Route element={<ColorRoute/>}>
                  <Route path="/" element={<Login setUserData={setUserData}/>} />
                  <Route path="/recovery" element={<PasswordRecovery/>} />
                  <Route path="/recovery-code" element={<PasswordRecoveryCode/>} />
                  <Route path="/recovery-code-form" element={<PasswordRecoveryForm/>} />
                  <Route path="/recovery-code-form-finish" element={<PasswordRecoveryFinish/>} />
                  <Route element={<AuthRoute setUserAccessToken={setUserAccessToken}/>}>
                    <Route element={<ProtectedRoute userData={userData} principalList={principalList}/>}>
                      <Route path="/dashboard" element={<Dashboard userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/insurance_policies" element={<Polizas userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/insurance_policies/:id" element={<PolizasDetails userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/insurance_policies/create" element={<CrearPoliza userData={userData} principalList={principalList} userList={userList} userAccessToken={userAccessToken}/>} />
                      <Route path="/insurance_policies_pending" element={<PendingPolizas userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/sellers" element={<Sellers userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/super_sellers" element={<SuperSellers userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/users" element={<Users userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/users/:id" element={<UserDetails userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/users/create" element={<CrearUser userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/claims" element={<Claims userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/claims/:id" element={<ClaimsDetails userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/claims/create" element={<CrearClaim userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/product_suggest" element={<ProductSuggest userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/product_suggest/create" element={<CrearProductSuggest userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/discounts" element={<Discounts userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/discounts/create" element={<CrearDiscounts userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/activities" element={<Activities userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/checkouts" element={<Checkouts userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/contact_forms" element={<ContactForm userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/motorbikes_prices" element={<MotorbikesPrices userData={userData} principalList={principalList} userList={userList}/>} />
                      <Route path="/car_prices" element={<CarPrices userData={userData} principalList={principalList} userList={userList}/>} />
                    </Route>
                  </Route>
                </Route>
              </React.Fragment>
            }
          </Routes>
        </Router>
      </IntlProvider>
    </div>
  );
}

export default App;