import React from 'react'
import './EstadoListModal.css'
import { estadoList } from '../../../util/EstadoListColors'


const EstadoListModal = (props: any) => {

    return (
        <div className='estadoListModal-container'>
            <div className='estadoListModal-container-top'>
                <b className='estadoListModal-container-top-text bold medium'>Tipos de estado de cobertura</b>
                <div onClick={() => props.onDismiss()} className='estadoListModal-container-top-close'>
                    <span className="material-symbols-rounded">close</span>
                </div>
            </div>
            <div className='estadoListModal-container-list'>
                {estadoList?.map((a: any) => (
                    <div className='estadoListModal-container-list-item'>
                        <b 
                            className='estadoListModal-container-list-item-title regular small'
                            style={{ color: a.color, backgroundColor: a.colorOpacity }}
                        >
                            {a.title}
                        </b>
                        <b className='estadoListModal-container-list-item-text regular small'>{a.text}</b>
                    </div>
                ))}
            </div>
        </div>
    );

}

export default EstadoListModal;