import axios from './instanceActions'
import {
    netPolizaData, 
    netPolizaDataFromClaim,
    netCheckoutData,
    netChangeStatus,
    netCheckOutRequerements
} from "../net/Connector";

export const getPolizaData = (userId: any, setPolizaData: any, from: any, setLoading: any) => {
    setLoading(true)
    let url = netPolizaData(userId)
    if(from === 'claim') {
        url = netPolizaDataFromClaim(userId)
    }
    axios.get(url)
        .then((response) => {
            setPolizaData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getCheckoutData = (id: any, setCheckoutData: any, setLoading: any) => {
    setLoading(true)
    axios.get(netCheckoutData(id))
        .then((response) => {
            setCheckoutData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
}

export const getCheckOutRequerements = (id: any, setCheckOutRequerements: any, setCheckOutRequerementsTOS: any, setLoading: any) => {
    setLoading(true)
    axios.get(netCheckOutRequerements(id))
        .then((response) => {
            setCheckOutRequerementsTOS(response?.data?.filter((a: any) => a?.input?.type === "ok"))
            setCheckOutRequerements(response?.data?.filter((a: any) => a?.input?.type !== "ok"))
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
}

export const putChangeStatus = (data: any, refreshData: any, sendNotification: any, setLoading: any) => {
    setLoading(true);
    axios({
        method: 'put',
        url: netChangeStatus(),
        data: data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response: any) => {
            refreshData();
            sendNotification({success: true, text: 'El estado de la cobertura se cambió con éxito.'})
            setTimeout(() => {
                sendNotification({success: false, text: ''})
            }, 5000)
            setLoading(false);
        })
        .catch((error) => {
            sendNotification({success: false, text: 'No pudimos cambiar el estado de la cobertura. Por favor, intentá nuevamente.'})
            setTimeout(() => {
                sendNotification({success: false, text: ''})
            }, 5000)
            setLoading(false);
        })
}
