import React, { useEffect, useState } from 'react'
import { 
    getListsArgPardo,
    getUserClientDataPardo,
    getLocalidadListPardo,
    getCodigoPostalListPardo,
    editUserProfileArgPardo
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './UserData.css'
import Moment from 'moment/moment'
import Lottie from 'react-lottie'
import loadingLottie from '../../../assets/lotties/loading.json'
import MainButton from '../../MainButton/MainButton'
import SelectorInput from '../../SelectorInput/SelectorInput'
import Modal from 'react-modal';
import CancelModal from '../../Modals/CancelModal/CancelModal'

const UserData = (props: any) => {

    //ORIGINAL DATA
    const [userClientData, setUserClientData] = useState<any>(null);

    //EDITABLE INPUTS
    const [tipoDeDocumento, setTipoDeDocumento] = useState<any>(null);
    const [nroDeDocumento, setNroDeDocumento] = useState<string>('');
    const [cuitOne, setCuitOne] = useState<string>('');
    const [cuitTwo, setCuitTwo] = useState<string>('');
    const [cuitThree, setCuitThree] = useState<string>('');

    const [sexo, setSexo] = useState<any>(null);
    const [estadoCivil, setEstadoCivil] = useState<any>(null);
    const [nacionalidad, setNacionlidad] = useState<any>(null);

    const [telefono, setTelefono] = useState<string>('');

    const [tipoDeDomicilio, setTipoDeDomicilio] = useState<any>(null);
    const [calle, setCalle] = useState<string>('');
    const [nro, setNro] = useState<string>('');
    const [piso, setPiso] = useState<string>('');
    const [depto, setDepto] = useState<string>('');

    const [localidad, setLocalidad] = useState<any>(null);
    const [provincia, setProvincia] = useState<any>(null);
    const [codigoPostal, setCodigoPostal] = useState<any>(null);

    //LISTS
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);

    const [sexoList, setSexoList] = useState<any>(null);
    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);
    const [nacionlidadList, setNacionalidadList] = useState<any>(null);

    const [tipoDomiList, setTipoDomiList] = useState<any>(null);

    const [provinciaList, setProvinciaList] = useState<any>(null);
    const [localidadList, setLocalidadList] = useState<any>(null);
    const [codigoPostalList, setCodigoPostalList] = useState<any>(null);

    //OTROS
    const [loading, setLoading] = useState<boolean>(false);
    const [cancelAlert, setCancelAlert] = useState<boolean>(false);

    //USE EFFECTS
    useEffect(() => {
        getUserClientDataPardo(props.userId, setUserClientData, props.from, setLoading)
    }, [])

    useEffect(() => {
        if (userClientData) getListsArgPardo(setTipoDocuList, setSexoList, setEstadoCivilList, setNacionalidadList, setTipoDomiList, setProvinciaList, setLocalidadList, setCodigoPostalList, userClientData)
    }, [userClientData])

    useEffect(() => {
        if(props.enableEdit) loadUserDataForEdit()
    }, [props.enableEdit])

    useEffect(() => {
        if(nroDeDocumento.length === 8) setCuitTwo(nroDeDocumento)
    }, [nroDeDocumento])

    //FUNCTIONS
    const loadUserDataForEdit = () => {
        if(userClientData?.documentId) setTipoDeDocumento(userClientData?.documentId)
        if(userClientData?.documentNumber) setNroDeDocumento(userClientData?.documentNumber)
        if(userClientData?.cuil) {
            setCuitOne(userClientData?.cuil?.slice(0, 2))
            setCuitTwo(userClientData?.cuil?.slice(2, -1))
            setCuitThree(userClientData?.cuil?.slice(-1))
        }

        if(userClientData?.sexId) setSexo(userClientData?.sexId)
        if(userClientData?.civilStatus) setEstadoCivil(userClientData?.civilStatus)
        if(userClientData?.nacionalityId) setNacionlidad(userClientData?.nacionalityId)

        if(userClientData?.phone) setTelefono(userClientData?.phone)

        if(userClientData?.addressType) setTipoDeDomicilio(userClientData?.addressType)
        if(userClientData?.addressStreet) setCalle(userClientData?.addressStreet?.slice(0, userClientData?.addressStreet?.search(/\d/) - 1))
        if(userClientData?.addressStreet) setNro(userClientData?.addressStreet?.slice(userClientData?.addressStreet?.search(/\d/)))
        if(userClientData?.addressFloor) setPiso(userClientData?.addressFloor)
        if(userClientData?.dpto) setDepto(userClientData?.dpto)

        if(userClientData?.provincia) {
            setProvincia({codigo: userClientData?.provinciaId?.toString()})
            getLocalidadListPardo(userClientData.provinciaId, setLocalidadList)
        }
        if(userClientData?.localidad) {
            setLocalidad({codigo: userClientData?.localidadId?.toString() + ' '})
            getCodigoPostalListPardo(userClientData.localidadId, setCodigoPostalList)
        }
        if(userClientData?.addressPostalCode) setCodigoPostal(userClientData?.addressPostalCode)
    }

    const handleProvinciaChange = (provincia: any) => {
        setProvincia(provincia)
        setLocalidadList(null)
        getLocalidadListPardo(provincia?.codigo, setLocalidadList)
        setLocalidad(null)
        setCodigoPostalList(null)
        setCodigoPostal(null)
    }

    const handleLocalidadChange = (localidad: any) => {
        setLocalidad(localidad)
        setCodigoPostalList(null)
        getCodigoPostalListPardo(localidad?.codigo, setCodigoPostalList)
        setCodigoPostal(null)
    }

    const isDisabled = () => {
        return (
            !tipoDeDocumento || nroDeDocumento?.length !== 8 || cuitOne === '' || cuitTwo === '' || cuitThree === '' ||
            !sexo || !estadoCivil || !nacionalidad ||
            telefono === '' ||
            !tipoDeDomicilio || calle === '' || nro === '' ||
            !localidad || !provincia || !codigoPostal
        )
    }

    //MODALS
    const CancelAlert = () => {
        return (
            <Modal
                isOpen={cancelAlert}
                onRequestClose={() => setCancelAlert(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '30%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <CancelModal
                    cancel={() => setCancelAlert(false)}
                    aceptar={() => {
                        setCancelAlert(false)
                        props.setEnabledEdit(false)
                    }}
                />
            </Modal>
        )
    }

    console.log(localidad)

    //MAIN RENDER
    return (
        <div className='userData-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <>
                    <div className='userData-container-data'>
                        <div className='userData-container-data-section'>
                            <CustomTextInput
                                label='FECHA DE CREACIÓN'
                                value={Moment(userClientData?.creationDate).format('DD/MM/YYYY')}
                                disabled
                            />
                            <CustomTextInput
                                label='ID'
                                value={userClientData?.id}
                                disabled
                            />
                            <div className='userData-container-data-section-status'>
                                <p className='userData-container-data-section-status-label bold medium'>PERFIL</p>
                                <p 
                                    className='userData-container-data-section-status-data regular small'
                                    style={{
                                        backgroundColor: userClientData?.profileCompleted ? 'var(--accent300)' : 'var(--accent500)'
                                    }}
                                >
                                    {userClientData?.profileCompleted ? 'COMPLETO' : 'INCOMPLETO'}
                                </p>
                            </div>
                        </div>
                        <h5 className='userData-container-data-title bold'>Datos personales</h5>
                        <div className='userData-container-data-section'>
                            <CustomTextInput
                                label='NOMBRE'
                                value={userClientData?.name}
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='APELLIDO'
                                value={userClientData?.lastName}
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='FECHA DE NACIMIENTO'
                                value={Moment(userClientData?.birthDate).format('DD/MM/YYYY')}
                                disabled
                                required
                            />
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>TIPO DE DOCUMENTO</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !tipoDocuList}
                                    id='TIPO DE DOCUMENTO'
                                    placeholder='Seleccioná el tipo de documento'
                                    label='TIPO DE DOCUMENTO'
                                    sortList={tipoDocuList}
                                    itemSelected={(a: any) => setTipoDeDocumento(a?.codigo)}
                                    value={props.enableEdit ? tipoDocuList?.filter((a: any) => a.codigo === tipoDeDocumento)[0]?.descripcion : tipoDocuList?.filter((a: any) => a.codigo === userClientData?.documentId)[0]?.descripcion}
                                />
                            </div>
                            <CustomTextInput
                                label='NRO DE DOCUMENTO'
                                value={props.enableEdit ? nroDeDocumento : userClientData?.documentNumber}
                                disabled={!props.enableEdit}
                                required
                                onChange={(e: any) => setNroDeDocumento(e.target.value.replace(/[^0-9]+/g, ''))}
                                maxLength={8}
                                error={props.enableEdit ? nroDeDocumento.length !== 8 : userClientData?.documentNumber?.length !== 8}
                                success={props.enableEdit ? nroDeDocumento.length === 8 : false}
                            />
                            <div className='userData-container-data-section-customInput'>
                                <div className='userData-container-data-section-customInput-label'>
                                    <b className='userData-container-data-section-customInput-label-text bold medium'>CUIT</b>
                                    <b className='userData-container-data-section-customInput-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <div className='userData-container-data-section-customInput-inputs'>
                                    <input
                                        className='userData-container-data-section-customInput-inputs-input regular medium'
                                        style={{
                                            width: '20%',
                                            backgroundColor: !props.enableEdit ? 'transparent' : 'var(--white)',
                                        }}
                                        value={props.enableEdit ? cuitOne : userClientData?.cuil?.slice(0, 2)}
                                        onChange={(e: any) => setCuitOne(e.target.value.replace(/[^0-9]+/g, ''))}
                                        maxLength={2}
                                    />
                                    <input
                                        className='userData-container-data-section-customInput-inputs-input regular medium'
                                        style={{ backgroundColor: 'transparent' }}
                                        value={props.enableEdit ? cuitTwo : userClientData?.cuil?.slice(2, -1)}
                                        disabled
                                    />
                                    <input
                                        className='userData-container-data-section-customInput-inputs-input regular medium'
                                        style={{
                                            width: '20%',
                                            backgroundColor: !props.enableEdit ? 'transparent' : 'var(--white)',
                                        }}
                                        value={props.enableEdit ? cuitThree : userClientData?.cuil?.slice(-1)}
                                        onChange={(e: any) => setCuitThree(e.target.value.replace(/[^0-9]+/g, ''))}
                                        maxLength={1}
                                    />
                                </div>
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>SEXO</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !sexoList}
                                    id='SEXO'
                                    placeholder='Seleccioná el sexo'
                                    label='SEXO'
                                    sortList={sexoList}
                                    itemSelected={(a: any) => setSexo(a?.codigo)}
                                    value={props.enableEdit ? sexoList?.filter((a: any) => a.codigo === sexo)[0]?.descripcion : sexoList?.filter((a: any) => a.codigo === userClientData?.sexId)[0]?.descripcion}
                                />
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>ESTADO CIVIL</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !estadoCivilList}
                                    id='ESTADO CIVIL'
                                    placeholder='Seleccioná el estado civil'
                                    label='ESTADO CIVIL'
                                    sortList={estadoCivilList}
                                    itemSelected={(a: any) => setEstadoCivil(a?.codigo)}
                                    value={props.enableEdit ? estadoCivilList?.filter((a: any) => a.codigo === estadoCivil)[0]?.descripcion : estadoCivilList?.filter((a: any) => a.codigo === userClientData?.civilStatus)[0]?.descripcion}
                                />
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>NACIONALIDAD</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !nacionlidadList}
                                    id='NACIONALIDAD'
                                    placeholder='Seleccioná la nacionalidad'
                                    label='NACIONALIDAD'
                                    sortList={nacionlidadList}
                                    itemSelected={(a: any) => setNacionlidad(a?.codigo)}
                                    value={props.enableEdit ? nacionlidadList?.filter((a: any) => a.codigo === nacionalidad)[0]?.descripcion : nacionlidadList?.filter((a: any) => a.codigo === userClientData?.nacionalityId)[0]?.descripcion}
                                />
                            </div>
                            <CustomTextInput
                                label='TELÉFONO'
                                value={props.enableEdit ? telefono : userClientData?.phone}
                                disabled={!props.enableEdit}
                                required
                                onChange={(e: any) => setTelefono(e.target.value.replace(/[^0-9]+/g, ''))}
                                error={props.enableEdit ? telefono === '' : userClientData?.phone === ''}
                                success={props.enableEdit ? telefono !== '' : false}
                            />
                        </div>
                        <h5 className='userData-container-data-title bold'>Datos de domicilio</h5>
                        <div className='userData-container-data-section'>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>TIPO DE DOMICILIO</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !tipoDomiList}
                                    id='TIPO DE DOMICILIO'
                                    placeholder='Seleccioná el tipo de domicilio'
                                    label='TIPO DE DOMICILIO'
                                    sortList={tipoDomiList}
                                    itemSelected={(a: any) => setTipoDeDomicilio(a?.codigo)}
                                    value={props.enableEdit ? tipoDomiList?.filter((a: any) => a.codigo === tipoDeDomicilio)[0]?.descripcion : tipoDomiList?.filter((a: any) => a.codigo === userClientData?.addressType)[0]?.descripcion}
                                />
                            </div>
                            <CustomTextInput
                                label='CALLE'
                                value={props.enableEdit ? calle : userClientData?.addressStreet?.slice(0, userClientData?.addressStreet?.search(/\d/) - 1)}
                                disabled={!props.enableEdit}
                                required
                                onChange={(e: any) => setCalle(e.target.value)}
                                error={props.enableEdit ? calle === '' : userClientData?.addressStreet?.slice(0, userClientData?.addressStreet?.search(/\d/) - 1) === ''}
                                success={props.enableEdit ? calle !== '' : false}
                            />
                            <div className='userData-container-data-section-triple'>
                                <CustomTextInput
                                    label='NRO'
                                    value={props.enableEdit ? nro : userClientData?.addressStreet?.slice(userClientData?.addressStreet?.search(/\d/))}
                                    disabled={!props.enableEdit}
                                    required
                                    onChange={(e: any) => setNro(e.target.value.replace(/[^0-9]+/g, ''))}
                                    error={props.enableEdit ? nro === '' : userClientData?.addressStreet?.slice(userClientData?.addressStreet?.search(/\d/)) === ''}
                                    success={props.enableEdit ? nro !== '' : false}
                                />
                                <CustomTextInput
                                    label='PISO'
                                    value={props.enableEdit ? piso : userClientData?.addressFloor}
                                    disabled={!props.enableEdit}
                                    placeholder='Opcional'
                                    onChange={(e: any) => setPiso(e.target.value.replace(/[^0-9]+/g, ''))}
                                />
                                <CustomTextInput
                                    label='DEPTO.'
                                    value={props.enableEdit ? depto : userClientData?.dpto}
                                    disabled={!props.enableEdit}
                                    placeholder='Opcional'
                                    onChange={(e: any) => setDepto(e.target.value)}
                                />
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>PROVINCIA</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !provinciaList}
                                    id='PROVINCIA'
                                    placeholder='Seleccioná la provincia'
                                    label='PROVINCIA'
                                    sortList={provinciaList}
                                    itemSelected={(a: any) => handleProvinciaChange(a)}
                                    value={props.enableEdit ? provinciaList?.filter((a: any) => a.codigo === provincia?.codigo)[0]?.descripcion : provinciaList?.filter((a: any) => a.descripcion === userClientData?.provincia)[0]?.descripcion}
                                />
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>LOCALIDAD</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !localidadList}
                                    id='LOCALIDAD'
                                    placeholder='Seleccioná la localidad'
                                    label='LOCALIDAD'
                                    sortList={localidadList}
                                    itemSelected={(a: any) => handleLocalidadChange(a)}
                                    value={props.enableEdit ? localidadList?.filter((a: any) => a.codigo === localidad?.codigo)[0]?.descripcion : userClientData?.localidad}
                                />
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>CÓDIGO POSTAL</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled={!props.enableEdit || !codigoPostalList}
                                    id='CÓDIGO POSTAL'
                                    placeholder='Seleccioná el código postal'
                                    label='CÓDIGO POSTAL'
                                    sortList={codigoPostalList}
                                    itemSelected={(a: any) => setCodigoPostal(a?.codigo)}
                                    value={props.enableEdit ? codigoPostalList?.filter((a: any) => a.codigo === codigoPostal)[0]?.descripcion : userClientData?.addressPostalCode}
                                />
                            </div>
                        </div>
                        <h5 className='userData-container-data-title bold'>Datos de cuenta</h5>
                        <div className='userData-container-data-section'>
                            <CustomTextInput
                                label='EMAIL'
                                value={userClientData?.username}
                                disabled
                                required
                            />
                        </div>
                    </div>
                    {props.enableEdit &&
                        <div className='userData-container-editable'>
                            <div className='userData-container-editable-editing'>
                                <MainButton
                                    text='CANCELAR'
                                    inverted
                                    onPress={() => setCancelAlert(true)}
                                />
                                <MainButton
                                    text='GUARDAR'
                                    disabled={isDisabled()}
                                    onPress={() => {
                                        props.setEnabledEdit(false)
                                        editUserProfileArgPardo(
                                            userClientData?.name, userClientData?.lastName, Moment(userClientData?.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                            tipoDeDocumento, nroDeDocumento, cuitOne + cuitTwo + cuitThree,
                                            sexo, estadoCivil, nacionalidad,
                                            telefono, userClientData?.username,
                                            tipoDeDomicilio, calle, nro, piso, depto,
                                            localidad?.codigo, localidad?.descripcion, codigoPostal, provincia?.codigo, provincia?.descripcion,
                                            userClientData?.id, setLoading, setUserClientData, props.setNotification, props.from
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    }
                </>
            }
            {CancelAlert()}
        </div>
    );

}

export default UserData;