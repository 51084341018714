import React, { useEffect, useState } from 'react'
import './CrearVendedorModal.css'
import Exit from '../../../assets/images/svgs/exit.svg';
import { ReactSVG } from 'react-svg'
import MainButton from '../../MainButton/MainButton';
import CustomTextInput from '../../CustomTextInput/CustomTextInput';
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/success.json'
import { actionsCreateItem, actionGetSucursalList } from '../../../actions';
import SelectorInput from '../../SelectorInput/SelectorInput';

const CrearVendedorModal = (props: any) => {

    const [nombre, setNombre] = useState<string>('')
    const [apellido, setApellido] = useState<string>('')
    const [dni, setDni] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [sucursal, setSucursal] = useState<any>(null)

    const [tipo, setTipo] = useState<string>('Sucursal');

    const [sucursalList, setSucursalList] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const isValidName = (e: string) => {
        if(!(/^\s/.test(e))) {
            return (/^[a-zA-Z\u00C0-\u00FF _]*$/.test(e))
        } return false
    };

    const isDisabled = () => {
        return (!validateEmail(email) || !isValidName(nombre) || !isValidName(apellido) || dni === '' || (tipo === 'Sucursal' && !sucursal))
    }

    useEffect(() => {
        actionGetSucursalList(setSucursalList)
    }, [])

    const disableSucursalInput = () => {
        if(tipo === 'Callcenter') return true
        if(sucursalList?.filter((a: any) => a.id === props.userData?.id)[0]) return true
        return false
    }

    useEffect(() => {
        if(sucursalList && props.userData) {
            if(sucursalList?.filter((a: any) => a.id === props.userData?.id)[0]) setSucursal(sucursalList?.filter((a: any) => a.id === props.userData?.id)[0])
        }
    }, [sucursalList, props.userData])
    
    return (
        <React.Fragment>
            {success ? 
                <div className='crearVendedorModal-container'>
                    <ReactSVG 
                        src={Exit}
                        style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                        onClick={() => {
                            props.onDismiss()
                            window.location.reload();
                        }}
                    />
                     <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: sentSuccess,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        width={223}
                        isStopped={false}
                        isPaused={false}
                        style={{margin: 0}}
                    />
                    <p className='crearVendedorModal-container-title bold'>¡Excelente!</p>
                    <p className='crearVendedorModal-container-subTitle regular large'>El vendedor ha sido creado exitosamente.<br/>Podrás visualizarlo en el listado de vendedores.</p>
                    <div className='crearVendedorModal-container-buttonWrap'>
                        <MainButton
                            text='ACEPTAR'
                            onPress={() => {
                                props.onDismiss()
                                window.location.reload();
                            }}
                        />
                    </div>
                </div>
                :
                <div className='crearVendedorModal-container'>
                    <ReactSVG 
                        src={Exit}
                        style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                        onClick={() => props.onDismiss()}
                    />
                    <p className='crearVendedorModal-container-title bold'>Crear vendedor</p>
                    <p className='crearVendedorModal-container-subTitle regular large'>Ingresá los siguientes datos para dar de alta un nuevo vendedor.</p>
                    <div className='crearVendedorModal-container-inputList'>
                        <CustomTextInput
                            label='APELLIDO'
                            onChange={(e: any) => {
                                setApellido(e.target.value)
                                setError(null)
                            }}
                            value={apellido}
                            error={apellido.length > 0 && !isValidName(apellido)}
                            success={apellido.length > 0 && isValidName(apellido)}
                            required
                            errorMessage={error?.message}
                        />
                        <CustomTextInput
                            label='NOMBRE'
                            onChange={(e: any) => {
                                setNombre(e.target.value)
                                setError(null)
                            }}
                            value={nombre}
                            error={nombre.length > 0 && !isValidName(nombre)}
                            success={nombre.length > 0 && isValidName(nombre)}
                            required
                            errorMessage={error?.message}
                        />
                        <CustomTextInput
                            label='DNI'
                            placeholder='Sólo números'
                            onChange={(e: any) => setDni(e.target.value.replace(/[^0-9]+/g, ''))}
                            success={dni !== ''}
                            value={dni}
                            required
                            maxLength={8}
                            error={error?.field === "dni"}
                            errorMessage={error?.message}
                        />
                        <CustomTextInput
                            label='E-MAIL'
                            placeholder='nombre@ejemplo.com'
                            onChange={(e: any) => setEmail(e.target.value)}
                            value={email}
                            error={(email.length > 0 && !validateEmail(email)) || error?.field === "email"}
                            errorMessage={error?.message}
                            success={validateEmail(email)}
                            required
                        />
                    </div>
                    <div className='crearVendedorModal-container-row'>
                        <div className='crearVendedorModal-container-type'>
                            <b className='crearVendedorModal-container-type-title medium bold'>TIPO DE VENDEDOR</b>
                            <div className='crearVendedorModal-container-type-list'>
                                <div 
                                    className='crearVendedorModal-container-type-list-item'
                                    onClick={() => {
                                        setTipo('Sucursal')
                                        if(sucursalList?.filter((a: any) => a.id === props.userData?.id)[0]) setSucursal(sucursalList?.filter((a: any) => a.id === props.userData?.id)[0])
                                    }}
                                >
                                    <div className='crearVendedorModal-container-type-list-item-circle'>
                                        <div 
                                            className='crearVendedorModal-container-type-list-item-circle-inner'
                                            style={{ transform: tipo === 'Sucursal' ? `scale(${1})` : `scale(${0})` }}
                                        />
                                    </div>
                                    <b className='crearVendedorModal-container-type-list-item-text medium bold'>SUCURSAL</b>
                                </div>
                                <div 
                                    className='crearVendedorModal-container-type-list-item'
                                    onClick={() => {
                                        setTipo('Callcenter')
                                        setSucursal(null)
                                    }}
                                >
                                    <div className='crearVendedorModal-container-type-list-item-circle'>
                                        <div 
                                            className='crearVendedorModal-container-type-list-item-circle-inner'
                                            style={{ transform: tipo === 'Callcenter' ? `scale(${1})` : `scale(${0})` }}
                                        />
                                    </div>
                                    <b className='crearVendedorModal-container-type-list-item-text medium bold'>CALLCENTER</b>
                                </div>
                            </div>
                        </div>
                        <div className='crearVendedorModal-container-selector'>
                            <div className='crearVendedorModal-container-selector-label'>
                                <b className='crearVendedorModal-container-selector-label-text bold medium'>SUCURSAL</b>
                                <b className='crearVendedorModal-container-selector-label-required bold medium'>*</b>
                            </div>
                            <SelectorInput
                                disabled={disableSucursalInput()}
                                id='SUCURSAL'
                                placeholder='Seleccioná la sucursal'
                                label='SUCURSAL'
                                sortList={sucursalList}
                                itemSelected={(a: any) => setSucursal(a)}
                                value={sucursal?.branchName}
                                deleteSelectedItem={() => setSucursal(null)}
                            />
                        </div>

                    </div>
                    <div className='crearVendedorModal-container-buttonList'>
                        <MainButton
                            text='CANCELAR'
                            sinFondo
                            onPress={() => props.onDismiss()}
                        />
                        <MainButton
                            text={loading ? 'CREANDO...' : 'FINALIZAR'}
                            disabled={isDisabled()}
                            onPress={() => {
                                const data = {
                                    "name": nombre,
                                    "lastName": apellido,
                                    "userName": email,
                                    "branchName": sucursal?.branchName,
                                    "addressFloor": '_',
                                    "addressFormatGM": '_',
                                    "addressPostalCode": '_',
                                    "addressStreet": '_',
                                    "birthDate": '2023-07-07T13:58:16.958Z',
                                    "civilStatusId": 0,
                                    "colonia": '_',
                                    "cuil": '_',
                                    "delegacion": '_',
                                    "documentId": '1',
                                    "documentNumber": dni,
                                    "localidad": 0,
                                    "motherLastName": '_',
                                    "nacionalityId": '2',
                                    "phone": '_',
                                    "provincia": 0,
                                    "sexId": 'male',
                                    "password": 'fds%&-efe24%&^&',
                                    "roles": [5],
                                    "tipoVendedor": tipo
                                }
                                actionsCreateItem(data, '/v2/user/fast_user', setLoading, setSuccess, setError)
                            }}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    );

}

export default CrearVendedorModal;