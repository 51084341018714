import React, { useEffect, useState } from "react";
import "./DateBetweenComponent.css";
import Moment from "moment/moment";

const DateBetweenComponent = (props: any) => {
  const [localFecha, setLocalFecha] = useState<string>(() => {
    const storedValue = sessionStorage.getItem(props.data?.text);
    return storedValue === null ? "" : JSON.parse(storedValue);
  });
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [fechaAplied, setFechaAplied] = useState<any>(null);

  const validDates = () => {
    return Moment(localFecha, "YYYY-MM-DD", true).isValid();
  };

  function replaceValue(obj: any, keyToReplace: any, replacementText: any) {
    if (obj instanceof Object) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'string') {
            obj[key] = obj[key].replace(new RegExp(keyToReplace, 'g'), replacementText);
          } else if (obj[key] instanceof Object) {
            replaceValue(obj[key], keyToReplace, replacementText);
          }
        }
      }
    }
    return obj;
  }

  const updateObject = (originalObject: any, inputValue: any) => {
    const updatedObject = { ...originalObject };
    // Loop through the originalObject and its nested properties
    const newFilter = replaceValue(
      updatedObject,
      fechaAplied ? fechaAplied : '\\$VALOR',
      inputValue
    );
    setFechaAplied(inputValue);

    return newFilter;
  };

  useEffect(() => {
    if (validDates() && props.data.text==='Desde') {
      const fecha = Moment(localFecha, "YYYY-MM-DD").format("DD/MM/YYYY")
      props.changeValue(
        updateObject(
          props.data?.filter,
          Moment(fecha, "DD/MM/YYYY")
            .utcOffset("+0000")
            .startOf("day")
            .toISOString()
        )
      );
    }
    if (validDates() && props.data.text==='Hasta') {
      const fecha = Moment(localFecha, "YYYY-MM-DD").format("DD/MM/YYYY")
      props.changeValue(
        updateObject(
          props.data?.filter,
          Moment(fecha, "DD/MM/YYYY")
            .utcOffset("+0000")
            .endOf("day")
            .toISOString()
        )
      );
    }
  }, [localFecha]);

  return (
    <div className="DateBetweenComponent-container">
      <p className="DateBetweenComponent-container-text regular small">
        {props.data.text}
      </p>
      <div className="DateBetweenComponent-container-inputContainer">
        <input
          id="date-input"
          className="DateBetweenComponent-container-inputContainer-input light medium"
          placeholder="DD/MM/AAAA"
          onChange={(e: any) => setLocalFecha(e.target.value)}
          value={localFecha}
          maxLength={10}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          style={{
            border: inputFocus
              ? "1px solid var(--primary500)"
              : localFecha.length === 0 || validDates()
              ? "1px solid var(--gray300)"
              : "1px solid var(--errorPrimary)",
          }}
          defaultValue={localFecha !== "" ? localFecha : undefined}
          type="date"
          max="2999-12-31"
        />
      </div>
      {localFecha.length === 0 ||
        (!validDates() && (
          <p className="DateBetweenComponent-container-error regular small">
            Formato inválido.
          </p>
        ))}
    </div>
  );
};

export default DateBetweenComponent;
