import axios from './instanceActions'
import { 
    netTableDownload,
    netTableDownloadCheck,
    netTableHeader,
    netTableSearch,
    netCreateItem,
    netDeleteTableRow,
    netReadContact,
    netDownloadTxt
} from "../net/Connector";

export const actionsTableHeader = (setChartData: any, setFormData: any, setChartDataRows: any, data: any, setLoadingChart: any, type: string, search: string) => {
    setLoadingChart(true)
    axios.get(netTableHeader(type))
        .then((response) => {
            setChartData(response.data.chart)
            setFormData(response.data.form)
            axios({
                method: 'post',
                url: netTableSearch(search),
                data
            })
                .then((responseB) => {
                    setChartDataRows(responseB.data)
                    setLoadingChart(false)
                })
                .catch((error) => {
                    setLoadingChart(false)
                })
        })
        .catch((error) => {
            setLoadingChart(false)
        })
};

export const actionsTableDataRows = (setChartDataRows: any, data: any, setLoadingChart: any, search: string) => {
    setLoadingChart(true)
    axios({
        method: 'post',
        url: netTableSearch(search),
        data
    })
        .then((responseB) => {
            setChartDataRows(responseB.data)
            setLoadingChart(false)
        })
        .catch((error) => {
            setLoadingChart(false)
        })
};

export const actionsTableDownload = (dataFilter: any, setDownloading: any, type: string) => {
    const data: any = { name: type }
    if(dataFilter) {
        data['filter'] = dataFilter;
    }
    setDownloading(true)
    axios({
        method: 'post',
        url: netTableDownload(),
        data
    })
        .then((response: any) => {
            const downloadStatus = () => {
                axios(netTableDownloadCheck(response.data.id))
                    .then(responseStat => {
                        if(responseStat.data.status === "COMPLETED") {
                            window.open(responseStat.data.url);
                            clearInterval(refreshIntervalId);
                            setDownloading(false)
                        }
                    })
                    .catch(error => {
                        clearInterval(refreshIntervalId);
                        setDownloading(false)
                    })
            }
            var refreshIntervalId = setInterval(downloadStatus, 2000);
        })
        .catch((error) => {
            setDownloading(false)
        })
};

export const actionsCreateItem = (data: any, url: any, setLoading: any, setSuccess: any, setError: any) => {
    setLoading(true)
    axios({
        method: 'post',
        url: netCreateItem(url),
        data
    })
        .then((response: any) => {
            setSuccess(true)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
            setError(error?.response?.data?.errors[0])
        })
}

export const actionsDeleteTableRow = (userId: any, url: any, setLoading: any, setSuccess: any) => {
    setLoading(true)
    axios({
        method: 'delete',
        url: netDeleteTableRow(url),
        params: { userId: userId }
    })
        .then((response: any) => {
            setSuccess(true)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
}

export const actionsReadContact = (contactId: any) => {
    axios({
        method: 'post',
        url: netReadContact(contactId)
    })
        .then((response: any) => {
            window.location.reload();
        })
        .catch((error) => {
        })
}

export const actionsDownloadTxt = (dataSend: any, date: any, setLoading: any) => {
    setLoading(true);
    axios({
        method: 'post',
        url: netDownloadTxt(),
        data: dataSend,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response: any) => {
            const txtContent = response.data;
            const blob = new Blob([txtContent], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `SALPARDO_${date}.txt`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        })
}