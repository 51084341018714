export const data = null;

export let sonLogo = 'https://resources.wecover.co/pardoLogo.svg';
export let sonLogoBlanco = 'https://resources.wecover.co/Pardo-Logo-blanco.svg';

export let sonLogoSize  = '10%';

export const changeLogo = (url: string) => {
    sonLogo = url;
}

export const countries = [
    {
        id: 1,
        title: "ARGENTINA",
        img: null,
        url: '',
        lang: () => import('./assets/lang/es-AR.json'),
    }
];