import React, { useState } from 'react'
import './GeneralCard.css'
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/cardSkeleton.json'
import { useNavigate } from 'react-router-dom';

const GeneralCard = (props) => {
    const navigate = useNavigate();
    const [showLayer, setShowLayer] = useState(false);
    const handleClick=()=>{
        if(props.data[0]==='Coberturas vigentes'){
            sessionStorage.setItem('Vigente',true);
        }
        if(props.data[0]==='Solicitudes pendientes'){
            sessionStorage.setItem('Solicitud pendiente',true);
        }
        if(props.data[0]==='Vendedores activos'){
            sessionStorage.setItem('Activo',true);
        }
        if(props.data[4]) navigate(props.data[4])
    }

    const layerStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 15,
        pointerEvents: 'none', // To allow interactions with underlying components
    };

    if(props.loading) {
        return (
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: sentSuccess,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                width={223}
                isStopped={false}
                isPaused={false}
                style={{margin: 0}}
            />
        )
    }

    return (
        <div 
            className='generalCard-container'
            style={{
                backgroundColor: props.isFirst ? 'var(--primary200)' : props.data[3], marginLeft: props.isFirst ? 0 : 20,
                cursor: props.data[4] ? 'pointer' : 'auto'
            }}
            onClick={() => { handleClick() }}
            onMouseDown={() => { if(props.data[4]) setShowLayer(true)}}
            onMouseUp={() => { if(props.data[4]) setShowLayer(true)}}
        >
            <div className='generalCard-container-metric'>
                <h2 className='generalCard-container-metric-text bold'>{props.data[1]}</h2>
                <span className="material-symbols-rounded" style={{ fontSize: 40 }}>{props.data[2]}</span>
            </div>
            <h6 className='generalCard-container-description regular'>{props.data[0]}</h6>
            {showLayer && <div style={layerStyle}></div>}
        </div>
    );

}

export default GeneralCard;