import React, { useEffect, useRef, useState } from 'react'
import { 
    getPolizaData,
    getCheckoutData,
    getCheckOutRequerements
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './PolizasItem.css'
import loadingLottie from '../../../assets/lotties/loading.json'
import Lottie from 'react-lottie';
import ItemPregunta from './ItemPregunta/ItemPregunta'

const PolizasItem = (props: any) => {

    const [itemData, setItemData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [checkoutData, setCheckoutData] = useState<any>(null)
    const [checkOutRequerements, setCheckOutRequerements] = useState<any>(null)
    const [checkOutRequerementsTOS, setCheckOutRequerementsTOS] = useState<any>(null)
    const [openItem, setOpenItem] = useState<boolean>(false)

    const formRef: any = useRef();

    useEffect(() => {
        getPolizaData(props.polizaId, setItemData, props.from, setLoading)
    }, [])

    useEffect(() => {
        if(itemData) {
            getCheckoutData(itemData?.checkoutId, setCheckoutData, setLoading)
            getCheckOutRequerements(itemData?.checkoutId, setCheckOutRequerements, setCheckOutRequerementsTOS, setLoading)
        }
    }, [itemData])

    useEffect(() => {
        if(openItem) {
            formRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [openItem])

    useEffect(() => {
        if(props.openItem) setOpenItem(true)
    }, [props.openItem])

    return (
        <div className='polizasItem-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='polizasItem-container-data'>
                    <div className='polizasItem-container-data-section'>
                        <CustomTextInput
                            label='CATEGORÍA'
                            value={itemData?.productPolicyDTO?.categoryName}
                            disabled
                        />
                    </div>
                    {checkoutData?.productInfo && <div className='polizasItem-container-data-item'>
                        <h5 className='polizasItem-container-data-item-title bold'>Declaración jurada</h5>
                        <div className='polizasItem-container-data-item-data'>
                            <div className='polizasItem-container-data-item-data-head'>
                                <div className='polizasItem-container-data-item-data-head-texts'>
                                    <b className='regular medium'>CONTRATANTE/AFILIADO</b>
                                    <b className='regular medium'>Cuestionario de salud</b>
                                </div>
                                <div 
                                    className='polizasItem-container-data-item-data-head-icon'
                                    onClick={() => setOpenItem(!openItem)}
                                    style={{ transform: openItem ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                >
                                    <span className="material-symbols-rounded" style={{ color: 'var(--primary500)' }}>expand_more</span>
                                </div>
                            </div>
                            {openItem &&
                                <div className='polizasItem-container-data-item-data-main'>
                                    <p className='polizasItem-container-data-item-data-main-title bold'>Por favor, respondé “si” o “no” a las siguientes preguntas</p>
                                    <div className='polizasItem-container-data-item-data-main-form' ref={formRef}>
                                        {checkOutRequerements?.map((a: any, index: any) => (
                                            <ItemPregunta
                                                key={index}
                                                numero={index + 1}
                                                isLast={checkOutRequerements?.length === index + 1}
                                                pregunta={a?.input?.title}
                                                selected={checkoutData?.productInfo?.[a.id][0] === "1" ? true : false}
                                            />
                                        ))}
                                    </div>
                                    <div className='polizasItem-container-data-item-data-main-tos'>
                                        <div className='polizasItem-container-data-item-data-main-tos-box'>
                                            <span className="material-symbols-rounded" style={{ color: 'var(--white)', fontSize: 15 }}>done</span>
                                        </div>
                                        {checkOutRequerementsTOS && <b className='polizasItem-container-data-item-data-main-tos-text regular medium' dangerouslySetInnerHTML={{ __html: checkOutRequerementsTOS[0]?.input?.config?.text }} />}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>}
                </div>
            }
        </div>
    );

}

export default PolizasItem;