import React, { useState, useRef, useEffect } from 'react'
import './TableContent.css'
import {ReactComponent as Arrow} from '../../assets/images/svgs/leftArrow.svg';
import SimpleTextComponent from './SimpleTextComponent/SimpleTextComponent';
import TitleAndSubTitleComponent from './TitleAndSubTitleComponent/TitleAndSubTitleComponent';
import TextWithBackGround from './TextWithBackGround/TextWithBackGround';
import AlertComponent from './AlertComponent/AlertComponent';
import TextWithColor from './TextWithColor/TextWithColor';
import DateComponent from './DateComponent/DateComponent';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import DeleteRowModal from '../Modals/DeleteRowModal/DeleteRowModal';
import DeleteComponent from './DeleteComponent/DeleteComponent';
import { actionsReadContact } from '../../actions';
import MailComponent from './MailComponent/MailComponent';
import EstadoListModal from '../Modals/EstadoListModal/EstadoListModal';
import TableBox from './TableBox/TableBox';
import GlobalDelete from './GlobalActions/GlobalDelete/GlobalDelete';
import GlobalStatusChange from './GlobalActions/GlobalStatusChange/GlobalStatusChange';
import GlobalDownload from './GlobalActions/GlobalDownload/GlobalDownload';
import IndividualActions from './IndividualActions/IndividualActions';

const TableContent = (props) => {  
    
    const [hoveredR, setHoveredR] = useState(null);
    const [deleteRowAlert, setDeleteRowAlert] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [estadoListAlert, setEstadoListAlert] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const [openSelection, setOpenSelection] = useState(false);
    
    const optionSelectionRef = useRef();

    const listIndividual = [
        {
            'label': 'Editar',
            'show': props.canEditCustom,
            'action': () => props.editTableItem(true)
        },
        {
            'label': 'Eliminar',
            'show': props.canDelete,
            'action': () => setDeleteRowAlert(true)
        }
    ]

    const getComponent = (indexB, b, isHovered) => {
        const dataStyle = {
            "AlertComponent": { component: AlertComponent },
            "TextWithBackGround": { component: TextWithBackGround },
            "TitleAndSubTitleComponent": { component: TitleAndSubTitleComponent },
            "TextWithColor": { component: TextWithColor },
            "DateComponent": { component: DateComponent },
            "DeleteComponent": { component: DeleteComponent },
            "MailComponent": { component: MailComponent },
            undefined: { component: SimpleTextComponent }
        }
        
        if(props.headers && indexB !== null) {
            const StepComponent = dataStyle[props.headers[indexB]?.component]?.component;
    
            return (
                <StepComponent
                    data={b}
                    fill={props.headers[indexB]?.config?.fill}
                    style={props.headers[indexB]?.style}
                    hovered={isHovered}
                    userData={props.userData}
                    refreshData={props.refreshData}
                    sendNotification={props.sendNotification}
                />
            )
        } return null;

    }

    const getGlobalActionComponent = (data) => {
        const globalActionStyle = {
            "delete": { component: GlobalDelete },
            "compare_arrows": { component: GlobalStatusChange },
            "txt": { component: GlobalDownload },
            undefined: { component: null }
        }
        
        if(data?.icon) {
            const GlobalActionComponent = globalActionStyle[data?.icon]?.component;
    
            return (
                <GlobalActionComponent
                    selectedList={selectedList}
                    tooltip={data?.tooltip}
                    list={data?.list}
                    refreshData={props.refreshData}
                    sendNotification={props.sendNotification}
                    userData={props.userData}
                    deleteData={props.deleteData}
                    deleteMultipleData={props.deleteMultipleData}
                    dataRows={props.dataRows}
                />
            )
        } return null;

    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (optionSelectionRef.current && !optionSelectionRef.current.contains(event.target)) {
                setOpenSelection(false) 
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionSelectionRef]);

    const transformStringToFunction = (str) => {
        return new Function('data', `return ${str}`);
    };

    const handleRowClick = (event, data) => {
        const row = event.currentTarget;
        try {
            const idCell = row.querySelector('td:nth-of-type(' + (Array.from(row.parentElement.rows[0].cells).findIndex(cell => cell?.textContent === props.idType) + 1) + ')');
            if(props.hasDetails) props.goToDetails(idCell.textContent.match(/^\d+/))
        } catch {

        }
        const inputString = props.deleteData?.data;
        const transformedFunction = transformStringToFunction(inputString);
        const result = transformedFunction(data);
        setDeleteRowData(result)
        if(props.setEditData)props.setEditData(data)
        setDeleteId(data[1])
    };

    const handleDataClick = (event, data) => {
        if(event?.currentTarget?.innerText?.includes("Descargar como .txt")) event.stopPropagation();
        if(event?.currentTarget?.innerText?.includes('arrow_drop_down')) event.stopPropagation();
        if(event?.currentTarget?.innerText === "delete" && props.deleteData) setDeleteRowAlert(true)
        if(event?.currentTarget?.innerText === "drafts") {
            try {
                const parsedObject = JSON.parse(data);
                actionsReadContact(parsedObject.id)
            } catch (error) {
            }
        }
    }

    const DeleteRowAlert = () => {
        return (
            <Modal
                isOpen={deleteRowAlert}
                onRequestClose={() => {
                    setDeleteRowAlert(false)
                    window.location.reload();
                }}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '30%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <DeleteRowModal
                    title={props.deleteData?.title}
                    data={deleteRowData}
                    deleteId={deleteId}
                    subTitleSuccess={props.deleteData?.subTitleSuccess}
                    onDismiss={() => setDeleteRowAlert(false)}
                />   
            </Modal>
        )
    }

    const EstadoListAlert = () => {
        return (
            <Modal
                isOpen={estadoListAlert}
                onRequestClose={() => setEstadoListAlert(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '640px',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <EstadoListModal
                    onDismiss={() => setEstadoListAlert(false)}
                />   
            </Modal>
        )
    }

    const isDisabled = (row) => {
        try {
            const res = JSON.parse(row[6]);
            return res?.h1 === 'Inactivo'
        } catch (error) {
            try {
                const res = JSON.parse(row[7]);
                return res?.h1 === 'Inactivo'
            } catch {
                
            }
            return false
        }
    }

    const notViewedMessage = (row) => {
        try {
            const object = JSON.parse(row[5]);
            return (object?.leido === 'false')
        } catch (error) {
            return false
        }
    }

    const handleHeadInfoClick = (info) => {
        switch(info) {
            case 'EstadoList':
                setEstadoListAlert(true)
                break;
            default:
                break;
        }
    }

    const handleSelectedRow = (row) => {
        const list = [...selectedList];
        try {
            const object = JSON.parse(row[1]);
            var name;

            if(object?.name) {
                name = object?.name
            }
            else {
                name = object
            }
            // Check if the name already exists in the list
            const index = list.indexOf(name);

            if (index !== -1) {
                // If the name exists, remove it from the list
                list.splice(index, 1);
            } else {
                // If the name doesn't exist, add it to the list
                list.push(name);
            }

            setSelectedList(list);
        } catch (error) {
        }
    }

    const isSelected = (row) => {
        try {
            const object = JSON.parse(row[1]);
            if(object?.name) {
                return selectedList?.includes(object?.name);
            }
            else {
                return selectedList?.includes(object);
            }
        } catch (error) {
            return false
        }
    }

    const handleMassiveSeleccion = (control) => {
        if(selectedList.length !== 0 && !control) setSelectedList([])
        else {
            const list = [];
            props.dataRows?.map((a) => {
                try {
                    const object = JSON.parse(a[1]);
                    if(object?.name) {
                        list?.push(object?.name);
                    }
                    else {
                        list?.push(object);
                    }
                } catch (error) {
                }
            })
            setSelectedList(list);
        }
    }

    const emptyTable = () => {
        if(props.dataConfig?.filter?.length !== 0) { //En base a un filtro
            return (
                <div className='tableContent-container-empty'>
                    <span className="material-symbols-rounded" style={{ color: 'var(--gray200)', fontSize: 60}}>sentiment_dissatisfied</span>
                    <h5 className='tableContent-container-empty-title light'>¡Lo sentimos mucho!</h5>
                    <p className='tableContent-container-empty-text regular'>No encontramos resultados que coincidan con tu búsqueda.<br/>Por favor, intentá nuevamente.</p>
                </div>
            )
        } else {
            return (
                <div className='tableContent-container-empty'>
                    <span className="material-symbols-rounded" style={{ color: 'var(--gray200)', fontSize: 60}}>schedule</span>
                    <h5 className='tableContent-container-empty-title light'>Lo bueno se hace esperar</h5>
                    <p className='tableContent-container-empty-text regular'>Actualmente no tenés {props.title?.toLowerCase()}.</p>
                </div>
            )
        }
    }

    return (
        <div className='tableContent-container'>
            <table id='main-table'>
                <tbody>
                    <tr>
                        {props.formData?.globalActions && <th style={{ width: '0%' }} className='tableContent-container-table-empty'></th>}
                        {props.headers?.map((a, index) => (
                            <th key={index}>
                                <div className='tableContent-container-tableHeadeContainer'>
                                    <b className='tableContent-container-tableHeadeContainer-text bold small'>{a.title?.toUpperCase()}</b>
                                    {a.info && <span onClick={() => handleHeadInfoClick(a.info)} className="material-symbols-rounded" style={{fontSize: 20, marginLeft: 5, cursor: 'pointer', color: 'var(--gray400)'}}>info</span>}
                                </div>
                            </th>
                        ))}
                        {props.formData?.individualActions && <th style={{ width: '0%' }} className='tableContent-container-table-empty'></th>}
                    </tr>
                    {props.formData?.globalActions && <tr>
                        <th id="asd" className='tableContent-container-table-global' colSpan={props.headers?.length + 1}>
                            <div className='tableContent-container-table-global-content'>
                                <div className='tableContent-container-table-global-content-box'>
                                    <div 
                                        className='tableContent-container-table-global-box'
                                        style={{
                                            backgroundColor: selectedList?.length !== 0 ? 'var(--primary500)' : 'transparent'
                                        }}
                                        onClick={() => handleMassiveSeleccion()}
                                    >
                                        {selectedList?.length !== 0 ?
                                            <span className="material-symbols-rounded" style={{ color: 'var(--white)', fontSize: 15 }}>{selectedList?.length === props.dataRows?.length ? 'done' : 'horizontal_rule'}</span>
                                            :
                                            null
                                        }
                                    </div>
                                    <span className="tooltip-box">Seleccionar</span>
                                </div>
                                <div 
                                    className='tableContent-container-table-global-content-selection'
                                    style={{
                                        transform: openSelection ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'all 0.5ms ease'
                                    }}
                                >
                                    <span 
                                        className="material-symbols-rounded" 
                                        style={{ 
                                            color: 'var(--primary500)', 
                                            fontSize: 15,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setOpenSelection(!openSelection)}
                                    >arrow_drop_down</span>
                                </div>
                                {selectedList?.length !== 0 && <div className='tableContent-container-table-global-content-actions'>
                                    {props.formData?.globalActions?.map((a) => (
                                        <div className='tableContent-container-table-global-content-actions-item'>
                                            {getGlobalActionComponent(a)}
                                        </div>
                                    ))}
                                </div>}
                                {openSelection && 
                                    <div ref={optionSelectionRef} className='tableContent-container-table-global-content-selection-list'>
                                        <b 
                                            className='tableContent-container-table-global-content-selection-list-text regular medium'
                                            style={{ 
                                                borderTopLeftRadius: 15, 
                                                borderTopRightRadius: 15
                                            }}
                                            onClick={() => {
                                                handleMassiveSeleccion(true)
                                                setOpenSelection(false)
                                            }}
                                        >Seleccionar todo</b>
                                        <b 
                                            className='tableContent-container-table-global-content-selection-list-text regular medium'
                                            style={{ 
                                                borderBottomLeftRadius: 15, 
                                                borderBottomRightRadius: 15
                                            }}
                                            onClick={() => {
                                                setSelectedList([])
                                                setOpenSelection(false)
                                            }}
                                        >Borrar selección</b>
                                    </div>
                                }
                            </div>
                        </th>
                    </tr>}
                    {props.dataRows?.map((a, indexA) => (
                        <tr 
                            key={indexA}
                            onMouseOver={() => props.hasDetails ? setHoveredR(indexA) : {}}
                            onMouseLeave={() => props.hasDetails ? setHoveredR(null) : {}}
                            onClick={(event) => isDisabled(a) ? {} : handleRowClick(event, a)}
                            style={{ 
                                opacity: isDisabled(a) ? 0.4 : 1,
                                backgroundColor: notViewedMessage(a) ? 'var(--accent500)' : 'auto'
                            }}
                            className={props.hasDetails ? "table-rowHover" : "table-rowNoHover"}
                        >
                            {props.formData?.globalActions && <td 
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleSelectedRow(a);
                                }}
                            >
                                <TableBox
                                    isSelected={isSelected(a)}
                                />
                            </td>}
                            {a.map((b, indexB) => (
                                <td onClick={(event) => isDisabled(a) ? {} : handleDataClick(event, b)} key={indexB}>{getComponent(indexB, b, indexA === hoveredR)}</td>
                            ))}
                            {props.formData?.individualActions && <td><IndividualActions list={listIndividual} disabled={isDisabled(a)}/></td>}
                        </tr>
                    ))}
                </tbody>
            </table>
            {props.dataRows?.length === 0 && emptyTable()}
            {props.dataRows?.length !== 0 && <div className='tableContent-container-pageContainer'>
                <p className='tableContent-container-pageContainer-totalText'>{props.page?.totalItems ? props.page?.totalItems + ' Resultados encontrados' : 'Muchos resultados'}</p>
                <div className='tableContent-container-pageContainer-pages'>
                    {props.page?.currentPage !== 1 &&
                        <div className='tableContent-container-pageContainer-pages-wrap' onClick={() => props.prevPage()}>
                            <Arrow/>
                            <p className='tableContent-container-pageContainer-pages-text'>Anterior</p>
                        </div>
                    }
                    <p className='tableContent-container-pageContainer-pages-number'>{props.page?.currentPage} de {props.page?.pages}</p>
                    {props.page?.currentPage !== props.page?.pages &&
                        <div className='tableContent-container-pageContainer-pages-wrap' onClick={() => props.nextPage()}>
                            <p className='tableContent-container-pageContainer-pages-text'>Siguiente</p>
                            <Arrow style={{ transform: 'rotate(180deg)' }}/>
                        </div>
                    }
                </div>
            </div>}
            {DeleteRowAlert()}
            {EstadoListAlert()}
        </div>
    );
}

export default TableContent;