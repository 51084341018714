import React, {useEffect} from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './Polizas.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';
import { useLocation, useNavigate } from 'react-router-dom';
import SocialLinks from '../../components/SocialLinks/SocialLinks';

const Polizas = (props: any) => {
    //clear sessionStorage
    useEffect(() => {
        sessionStorage.clear();
        return () => {
            // Cleanup logic here
          };
      }, []);
      
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='polizas-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='polizas-container-main'>
                <div className='polizas-container-main-hedear'>
                    <div className='polizas-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                        mini
                    />
                </div>
                <GenericTableAndFilters
                    data={{
                        search: 'insurance_policy',
                        type: 'insurance_policies',
                        title: props.principalList?.filter((a: any) => a.path === location?.pathname)[0].name,
                        createPath: '/insurance_policies/create',
                        createTitle: 'CREAR COBERTURA',
                    }}
                    idType='COBERTURA'
                    goToDetails={(id: any) => navigate('/insurance_policies/' + id + '#Detalle%20de%20cobertura')}
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'insurance_policies:download' }).length > 0}
                    canCreate={props.userData?.privileges?.filter(function(e: any) { return e === 'insurance_policies:create' }).length > 0}
                    userData={props.userData}
                />
            </div>
            {props.userData?.role?.name === "Vendedor" && <SocialLinks vendedor={props.userData?.role?.name === "Vendedor"}/>}
        </div>
    );
}

export default Polizas;