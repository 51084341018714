import React, { useEffect, useState } from 'react'
import './GenericTableAndFilters.css'
import TableContent from '../../components/TableContent/TableContent';
import {
    actionsTableDataRows,
    actionsTableDownload,
    actionsTableHeader
} from '../../actions';
import Lottie from 'react-lottie';
import sentSuccess from '../../assets/lotties/loading.json'
import TableFilters from '../../components/TableFilters/TableFilters';
import MainButton from '../../components/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import Notification from '../Notification/Notification';

const GenericTableAndFilters = (props) => {

    const [chartData, setChartData] = useState(null);
    const [chartDataRows, setChartDataRows] = useState(null);
    const [formData, setFormData] = useState(null);
    const [loadingChart, setLoadingChart] = useState(true);
    const [dataConfig, setDataConfig] = useState({ "page": { "number": 1, "size": 40 } });
    const [notification, setNotification] = useState({ success: false, text: '' });

    const navigate = useNavigate();

    useEffect(() => {
        actionsTableHeader(setChartData, setFormData, setChartDataRows, dataConfig, setLoadingChart, props.data.type, props.data.search)
    }, [])

    const handlePaging = (page) => {
        let data = dataConfig
        data.page.number = page;
        actionsTableDataRows(setChartDataRows, data, setLoadingChart, props.data.search)
    }

    return (
        <div className='genericTableAndFilters-container'>
            <div className='genericTableAndFilters-container-title'>
                <div className='genericTableAndFilters-container-title-texts'>
                    <h2 className='genericTableAndFilters-container-title-texts-title bold'>{props.data.title}</h2>
                    {props.data?.subTitle && <b className='genericTableAndFilters-container-title-texts-subTitle regular medium'>{props.data.subTitle}</b>}
                </div>
                {(props.canCreate || props.canCreateCustom) && <div className='genericTableAndFilters-container-title-buttonWrapper'>
                    <MainButton
                        text={props.data.createTitle}
                        inverted
                        onPress={() => {
                            if (props.canCreate) navigate(props.data.createPath)
                            else if (props.canCreateCustom) props.createTableItem()
                        }}
                    />
                </div>}
                <Notification
                    notification={notification}
                    setNotification={setNotification}
                />
            </div>
            <TableFilters
                chartData={chartData}
                formData={formData}
                dataConfig={dataConfig}
                setDataConfig={setDataConfig}
                sortChange={(data) => actionsTableDataRows(setChartDataRows, data, setLoadingChart, props.data.search)}
                download={(setDownloading) => actionsTableDownload(dataConfig?.filter, setDownloading, props.data.type)}
                canDownload={props.canDownload}
                canDownloadTxt={props.canDownloadTxt}
            />
            {loadingChart ?
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: sentSuccess,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{ margin: 'auto' }}
                />
                :
                <div className='genericTableAndFilters-container-table'>
                    <TableContent
                        headers={chartData?.columnConfig}
                        dataRows={chartDataRows?.data}
                        page={chartDataRows}
                        formData={formData}
                        dataConfig={dataConfig}
                        prevPage={() => handlePaging(chartDataRows.currentPage - 1)}
                        nextPage={() => handlePaging(chartDataRows.currentPage + 1)}
                        emptyText={props.emptyText}
                        title={props.data?.title}
                        idName={props.idName}
                        hasDetails={props.goToDetails}
                        goToDetails={(id) => props.goToDetails(id)}
                        deleteData={props.deleteData}
                        deleteMultipleData={props.deleteMultipleData}
                        idType={props.idType}
                        userData={props.userData}
                        refreshData={() => actionsTableDataRows(setChartDataRows, dataConfig, setLoadingChart, props.data.search)}
                        sendNotification={(data) => setNotification(data)}
                        canEditCustom={props.canEditCustom}
                        editTableItem={props.editTableItem}
                        canDelete={props.canDelete}
                        setEditData={props.setEditData}
                    />
                </div>
            }
        </div>
    );
}

export default GenericTableAndFilters;